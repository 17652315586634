<template>
  <div class="data-view">
    <!-- <dv-full-screen-container> -->
    <div style="min-height: 100vh">
      <common-header></common-header>
      <div class="wrapper">
        <div class="container-fluid">
          <a-row :gutter="16" type="flex">
            <a-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
              <a-row class="row blocks-3" :gutter="16" type="flex">
                <a-col :span="8">
                  <div class="card-box red-skin">
                    <div class="inner">
                      <div class="left-area">
                        <div class="hexagon">
                          <img
                            alt="image"
                            src="@/assets/img/icon-diamond_filled-red.png"
                            width="31"
                          />
                        </div>
                      </div>
                      <div class="right-area">
                        <h4 class="title">{{ $t('home.staticPool') }}</h4>
                        <div class="number big with-diamond">
                          {{ prizeAmount }}
                        </div>
                        <div class="number with-diamond-filled">
                          9,129,462.17
                        </div>
                      </div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="card-box yellow-skin">
                    <div class="inner">
                      <div class="left-area">
                        <div class="hexagon">
                          <img
                            alt="image"
                            src="@/assets/img/icon-diamond_filled-yellow.png"
                            width="31"
                          />
                        </div>
                      </div>
                      <div class="right-area">
                        <h4 class="title">{{ $t('home.dynamicPool') }}</h4>
                        <div class="number big with-diamond">
                          {{ savingAmount }}
                        </div>
                        <div class="number with-diamond-filled">
                          9,129,462.17
                        </div>
                      </div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="card-box blue-skin">
                    <div class="inner">
                      <div class="left-area">
                        <div class="hexagon">
                          <img
                            alt="image"
                            src="@/assets/img/icon-diamond-blue.png"
                            width="31"
                          />
                        </div>
                      </div>
                      <div class="right-area">
                        <h4 class="title">{{ $t('home.securityPool') }}</h4>
                        <div class="number big with-diamond">
                          {{ reserveAmount }}
                        </div>
                        <div class="number with-diamond-filled">
                          9,129,462.17
                        </div>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
              <div class="gutter"></div>
              <a-row lass="row" :gutter="16" type="flex">
                <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8"
                  ><x-smart
                /></a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16"
                  ><x-share
                /></a-col>
              </a-row>
            </a-col>
            <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
              <x-personal />
            </a-col>
          </a-row>
        </div>
      </div>
    </div>

    <!-- </dv-full-screen-container> -->
  </div>
</template>

<script>
import CommonHeader from '@/components/CommonHeader.vue';
import XSmart from './Smart.vue';
import XShare from './Share.vue';
import XPersonal from './Personal.vue';
export default {
  name: 'Home',
  data() {
    return {
      savingAmount: 0,
      reserveAmount: 0,
      prizeAmount: 0,
    };
  },
  components: {
    CommonHeader,
    XSmart,
    XShare,
    XPersonal,
  },
  mounted() {
    this.getSaving();
    this.getReserve();
    this.getPrize();
    // this.API.deposit("T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb", "1000");
  },
  methods: {
    async getSaving() {
      const savingAmount = await this.API.getSaving();
      this.savingAmount = this.API.fromSun(savingAmount);
      console.log(this.API.fromSun(savingAmount), 'savingAmount');
    },
    async getReserve() {
      const reserveAmount = await this.API.getReserve();
      this.reserveAmount = this.API.fromSun(reserveAmount);
      console.log(this.API.fromSun(reserveAmount), 'reserveAmount');
    },
    async getPrize() {
      const prizeAmount = await this.API.getPrize();
      this.prizeAmount = this.API.fromSun(prizeAmount);
      console.log(this.API.fromSun(prizeAmount), 'prizeAmount');
    },
  },
};
</script>
<style lang="less" scoped>
.gutter {
  height: 30px;
  clear: both;
  width: 100%;
}
.data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;
  #dv-full-screen-container {
    background-image: url('../assets/img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }
}
.wrapper {
  padding: 38px 0;
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .blocks-3 {
      margin-bottom: -15px;
    }
    .card-box {
      background: #171e26;
      padding: 12px;
      height: 100%;
      color: #89929f;
      font-size: 12px;
      .inner {
        padding: 10px 20px 10px;
        background: #0f141c;
        position: relative;
        height: 100%;
        overflow: hidden;
        display: flex;
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-top: 11px solid #171e26;
          border-left: 11px solid transparent;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .left-area {
        min-width: 60px;
        padding-right: 10px;
        text-align: center;
        .hexagon {
          background: url(../assets/img/icon-hexagon-red.svg) no-repeat center;
          background-size: contain;
          width: 54px;
          height: 62px;
          max-width: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 19px;
          }
        }
      }
      .right-area {
        .title {
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0 0 3px;
          font-weight: 400;
          color: #89929f;
        }
        .number.big {
          color: #fff;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 4px;
          letter-spacing: 1px;
        }
        .number.with-diamond:after {
          content: '';
          background: url(../assets/img/icon-diamond.png) no-repeat center left;
          background-size: contain;
          width: 18px;
          height: 17px;
          display: inline-block;
          margin-left: 4px;
        }
        .number {
          line-height: 15px;
          white-space: nowrap;
          letter-spacing: 2px;
        }
      }
      .number.with-diamond-filled:after {
        content: '';
        background: url(../assets/img/icon-diamond_filled.png) no-repeat center
          left;
        background-size: contain;
        width: 13px;
        height: 11px;
        display: inline-block;
        margin-left: 4px;
        position: relative;
        top: 1px;
      }
    }
    .card-box.yellow-skin {
      .hexagon {
        background-image: url(../assets/img/icon-hexagon-yellow.svg);
        width: 68px;
        height: 77px;
        margin-top: -6px;
        margin-bottom: -6px;
        img {
          max-width: 60px;
        }
      }
    }
    .card-box.blue-skin {
      .hexagon {
        background-image: url(../assets/img/icon-hexagon-blue.svg);
      }
    }
  }
}
</style>
