<template>
  <div class="share">
    <div class="tabs">
      <span
        class="tab-title"
        :class="cur === index ? 'active' : ''"
        v-for="(item, index) of tab"
        :key="index"
        @click="tabChange(index)"
      >
        {{ item }}
      </span>
    </div>
    <div class="tab-content">
      <div v-for="(item, index) of content" :key="index" v-show="cur == index">
        <component :is="componentName"></component>
      </div>
    </div>
  </div>
</template>
<script>
import XJoin from './tab/Join.vue';
import XInstructions from './tab/Instructions.vue';
import XPromote from './tab/Promote.vue';
export default {
  data() {
    return {
      // tab: [
      //   this.$t('share.tabOne'),
      //   this.$t('share.tabTwo'),
      //   this.$t('share.tabThree'),
      // ],
      content: [
        this.$t('share.tabOne'),
        this.$t('share.tabTwo'),
        this.$t('share.tabThree'),
      ],
      cur: 0,
      componentName: 'XPromote',
    };
  },
  computed: {
    tab() {
      return [
        this.$t('share.tabOne'),
        this.$t('share.tabTwo'),
        this.$t('share.tabThree'),
      ];
    },
  },
  components: {
    XJoin,
    XInstructions,
    XPromote,
  },
  methods: {
    tabChange(val) {
      this.cur = val;
      if (this.cur === 0) {
        this.componentName = 'XPromote';
      } else if (this.cur === 1) {
        this.componentName = 'XJoin';
      } else {
        this.componentName = 'XInstructions';
      }
    },
  },
};
</script>
<style lang="less" scoped>
.share {
  // display: flex;
  .tabs {
    width: 100%;
    .tab-title {
      text-align: center;
      float: left;
      text-transform: uppercase;
      cursor: pointer;
      background: #0f141c;
      border: 10px solid #171e26;
      border-right-width: 1px;
      border-left-width: 1px;
      width: calc(100% / 3);
      width: 33.33%;
      font-size: 18px;
      color: #98999b;
      letter-spacing: 1px;
      height: 78px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      &:nth-child(1) {
        border-left-width: 10px;
      }
      &:last-child {
        border-right-width: 10px;
      }
    }
    .tab-title.active {
      color: #ff004e;
      background: #0f0d19;
    }
  }
  .tab-content {
    padding: 50px 0 0;
    height: calc(100% - 80px);
    max-height: 100%;
    display: block;
    clear: both;
    background: #171e26;
  }
}
</style>
