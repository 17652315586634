<template>
  <div class="promote">
    <div class="welcome-box mobile-steps">
      <div class="siema">
        <div class="panel">
          <div class="welcome subscribe-user" id="user-subscribe-block">
            <div class="share">
              <img
                alt="image"
                src="@/assets/img/icon-diamond-blue.png"
                width="45"
              />
            </div>
            <div class="user-key">
              <div class="input-group">
                <input
                  class="form-control"
                  v-model="amount"
                  placeholder="100TRX起步"
                  @change="onChange"
                />
                <!-- <a-input type="text" class="form-control" /> -->
                <div class="input-group-append">
                  <span class="input-group-text">TRX</span>
                </div>
              </div>
              <div class="note">100 <b>TRX</b> {{ $t('share.start') }}</div>
              <a-space :size="size" style="margin-bottom: 20px">
                <a-button
                  class="btn-amount"
                  size="large"
                  v-for="(item, index) in btnList"
                  :key="index"
                  @click="changeInput(item)"
                >
                  {{ item.val }}
                </a-button>
              </a-space>
              <div class="form-group text-center">
                <a-button
                  type="submit"
                  class="btn btn-trapezoid btn-color"
                  @click="deposit"
                  :loading="loading"
                >
                  {{ $t('share.join') }}
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      amount: 0,
      size: 15,
      btnList: [
        // {
        //   val: '100 TRX',
        //   key: 100,
        // },
        {
          val: '1000 TRX',
          key: 1000,
        },
        {
          val: '5000 TRX',
          key: 1000,
        },
        {
          val: '10000 TRX',
          key: 10000,
        },
        {
          val: '30000 TRX',
          key: 30000,
        },
        {
          val: '100000 TRX',
          key: 100000,
        },
        // {
        //   val: '200000 TRX',
        //   key: 1000000,
        // },
      ],
      invitedAddress: '',
      loading: false,
      referrer: '',
    };
  },
  computed: {
    coinbase: {
      get() {
        return this.$store.state.tron.tron.coinbase;
      },
      set(value) {
        this.$store.commit('setCoinbase', value);
      },
    },
  },
  mounted() {
    this.getReferrer();
  },
  methods: {
    changeInput(item) {
      console.log(item, '11111');
      this.amount = item.key;
    },
    onChange(e) {
      console.log(e, '2222');
    },
    async getReferrer() {
      const referrer = await this.API.getReferrer(this.coinbase);
      this.referrer = this.API.fromHex(referrer);
      console.log(this.referrer, 'referrer');
    },
    // this.API.deposit("T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb", "1000");
    async deposit() {
      this.loading = true;
      this.InvitedAddress();
      console.log(this.amount, this.invitedAddress, 'amount');
      const depositRes = await this.API.deposit(
        this.invitedAddress,
        this.amount
      );
      this.loading = false;
      console.log(depositRes, 'depositRes');
    },
    InvitedAddress() {
      if (this.referrer === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb') {
        if (this.$route.query.ref) {
          this.invitedAddress = this.$route.query.ref;
        } else {
          let addressAdmin = [
            'TP4qnEMny8atPMDLfG9SwAsJKZ2HjFQPMe',
            'TJBvh2bJw1VC91GnzXxpoGjGT2edxZLroC',
          ];
          console.log(
            Math.floor(
              Math.random() * addressAdmin.length,
              'Math.floor(Math.random() * addressAdmin.length'
            )
          );
          this.invitedAddress =
            addressAdmin[Math.floor(Math.random() * addressAdmin.length)];
        }
      } else {
        this.invitedAddress = this.referrer;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.welcome-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  .welcome {
    justify-content: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    height: 100%;
    .share {
      margin-bottom: 37px;
      position: relative;
      float: right;
      text-transform: uppercase;
    }
  }
  .welcome.subscribe-user {
    color: #fff;
    .user-key {
      max-width: 640px;
      width: 100%;
      text-transform: uppercase;
      .input-group {
        border: 1px solid #3f4349;
        justify-content: center;
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
      }
      .form-control:valid {
        background: none;
        text-align: center;
        max-width: 100%;
        width: 100%;
        font-size: 15px;
        border: 0;
        color: #fff;
        height: 56px;
      }
      .input-group-append {
        position: absolute;
        right: 0;
        margin-left: -1px;
        display: flex;

        .input-group-text {
          font-size: 10px;
          color: #898a8c;
          padding: 0 16px;
          background: transparent;
          border: 0;
          height: 56px;
          line-height: 56px;
        }
      }
      .note {
        font-size: 10px;
        color: #939ba7;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align: center;
        margin: 10px 0 28px;
      }
      .btn {
        min-width: 260px;
      }
    }
  }
  .text-center {
    text-align: center !important;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .btn-trapezoid {
    background: #3d4552;
    color: rgba(255, 255, 255, 0.5);
    border: 0;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1.5px;
    font-weight: bold;
    min-width: 200px;
    max-width: 100%;
    padding: 14px 20px;
    -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 16px) 100%, 16px 100%);
    clip-path: polygon(0 0, 100% 0, calc(100% - 16px) 100%, 16px 100%);
  }
  .btn-color.btn-trapezoid {
    color: #fff;
    -webkit-clip-path: polygon(
      0 0,
      100% 0,
      calc(100% - 0.6875rem) 100%,
      0.6875rem 100%
    );
    clip-path: polygon(
      0 0,
      100% 0,
      calc(100% - 0.6875rem) 100%,
      0.6875rem 100%
    );
    background: #a70e35;
    background: -moz-linear-gradient(
      0deg,
      #a70e35 0%,
      #ec1842 50%,
      #a70e35 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, #a70e35),
      color-stop(50%, #ec1842),
      color-stop(100%, #a70e35)
    );
    background: -webkit-linear-gradient(
      0deg,
      #a70e35 0%,
      #ec1842 50%,
      #a70e35 100%
    );
    background: -o-linear-gradient(0deg, #a70e35 0%, #ec1842 50%, #a70e35 100%);
    background: -ms-linear-gradient(
      0deg,
      #a70e35 0%,
      #ec1842 50%,
      #a70e35 100%
    );
    background: linear-gradient(90deg, #a70e35 0%, #ec1842 50%, #a70e35 100%);
  }
  .btn-amount {
    color: #ff004e;
    background: #0f0d19;
    border: 0;
    height: 2.5rem;
  }
}
</style>
