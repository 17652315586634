// import { Spin } from 'ant-design-vue';
const tron = {
  state: {
    tron: {
      isInjected: false,
      tronWebInstance: null,
      coinbase: null,
      balance: 0
    }
  },
  mutations: {
    registerTronWebInstance(state, payload) {
      console.log("registerTronWebinstance Mutation being executed", payload);
      let result = payload;
      let copy = state.tron;
      copy.coinbase = result.coinbase;
      copy.balance = parseInt(result.balance, 10);
      copy.isInjected = result.injectedTronWeb;
      copy.tronWebInstance = result.tronWeb;
      state.tron = copy;
    },
    updateState(state, data) {
      state = { ...state, data };
    },
    pollTronWebInstance(state, payload) {
      console.log("pollTronWebInstance mutation being executed", payload);
      state.tron.coinbase = payload.coinbase;
      state.tron.balance = parseInt(payload.balance, 10);
    },
    setCoinbase(state, payload) {
      state.tron.coinbase = payload.coinbase;
    }
  },
  actions: {
    async registerTronWeb({ commit }) {
      // let loadingInstance = Spin.setDefaultIndicator({
      //     background: 'rgba(0, 0, 0, 0.7)'
      // })
      let tronWeb = window.tronWeb;
      let result = {
        injectedTronWeb: tronWeb.ready,
        coinbase: tronWeb.defaultAddress.base58,
        balance: null,
        tronWeb
      };
      // loadingInstance.close();
      result.balance = await tronWeb.trx.getBalance(result.coinbase);
      commit("registerTronWebInstance", result);
    },
    pollTronWeb({ commit }, payload) {
      console.log("pollTronWeb action being executed");
      commit("pollTronWebInstance", payload);
    }
  },
  getters: {}
};
export default tron;
