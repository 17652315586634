module.exports = {
   header : {
      balance: "Balance",
      collected: 'COLLECTED ROYALTIES',
      address: 'ADDRESS',
      timer: "TIMER",
      club: "TRX CLUB."
   },
   home:{
      staticPool: "STATICPOOL",
      dynamicPool: "DYNAMICPOOL",
      securityPool: "SECURITYPOOL",
   },
   smart:{
      tip: '100% TRANSPARENCY',
      address: 'CONTRACT ADDRESS',
      realTime: 'Realtime Service Volume',
      total: 'TOTAL ATTENTION',
      received: 'Received',
      todayReceived: 'Today Received',
      participation: 'Participation'
   },
   share:{
      tabOne: "Join",
      tabTwo: "Instructions",
      tabThree: "Share",
      start: 'Start',
      join: 'Join',
      title1:'Dangerous Technology?',
      title2:'OR JUST A GAME?',
      stepOneTitle:"You've stumbled into a crazy financial experiment!",
      stepOneDesc:"This is dangerous tech. Unless you'd like to find out how the world will use it, turn away now.",
      stepTwoTitle:'Be part of a service that decentralizes itself',
      stepTwoDesc:'Give it some TRX, help it expand, take rewards when it succeeds.',
      stepThreeTitle:'Start the conversation.',
      stepThreeDesc:"This software autonomously manages its own reputation. She'll pay you to help.",
      poolOneDesc: "Invitations get an immediate cut, even if they are participants you indirectly brought in!",
      poolTwoDesc:'Providing liquidity pays off in itself!',
      poolThreeDesc:'My last 100 participants are going to get a 1% cut The more you fund, the more you gain!',
      shareLink:'shareLink',
      shareTitle:'HOW IT WORKS',
      shareOneTitle:'1. Link to your wallet',
      shareOneDesc:'Register an address for AI to track your progress!',
      shareTwoTitle:'2. Share your promotional links',
      shareTwoDesc:"It's easy to start by sharing your unique promotion links with your followers!",
      shareThreeTitle:'3. Turn your liquidity into income.',
      shareThreeDesc:'The AI calculates the direct and indirect effects',
   },
   personal:{
      tabOne:'personal data',
      tabTwo:'personal promotion',
      todayCollect:'Collect today',
      withdraw:'WITHDRAW',
      referrer:'referrer',
      joinAmount:'joinAmount',
      totalReward:'totalReward',
      withdrawed:'withdrawed',
      pendingReward:'pendingReward',
      inviteeReward:'inviteeReward',
      withdrawablePendingReward:'withdrawablePendingReward',
      withdrawableInviteeReward:'withdrawableInviteeReward',
      historyTotalReward:'historyTotalReward',
      historyStaticReward:'historyStaticReward',
      historyInviteeReward:'historyInviteeReward',
   },
  tips: 'Tips',
  unlock_first: 'Please unlock your Tron Wallet',
  wallet_plugin_first: 'Please install the tronlink plugin first',
  login:'Login',
}