<template>
  <header class="header">
    <div class="container-fluid">
      <a-row :gutter="16" class="align-items-center" type="flex">
        <a-col class="header-left" :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <div class="user-info">
            <div class="item">
              <div class="my-account">
                <div class="item">
                  <div class="balance">
                    <div class="title with-diamond box">
                      {{ $t('header.balance') }}
                    </div>
                    <div class="number">
                      {{ coinbase === '' ? 0 : trxBalance }} TRX
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="balance">
                    <div class="title with-diamond">
                      {{ $t('header.collected') }}
                    </div>
                    <div class="number">0 TRX</div>
                  </div>
                </div>
                <div class="item">
                  <div v-if="!isInjected" class="login_btn" @click="login">
                    {{ $t('login') }}
                  </div>
                  <div class="balance" v-else>
                    <div class="title">{{ $t('header.address') }}</div>
                    <div class="text-copy svelte-1mv0e4h">
                      {{ coinbase ? coinbase : '--' }}
                      <span
                        class="icon-copy"
                        v-clipboard:copy="coinbase"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item item-score" style="margin: 0px auto">
              <div class="text-center">
                <a href="/" title="Logo"
                  ><img alt="image" src="@/assets/img/logo.png" class="logo"
                /></a>
              </div>
            </div>
          </div>
        </a-col>
        <a-col
          class="text-right header-right"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="8"
          :xl="8"
        >
          <div class="d-flex">
            <div class="logo mobile--only">
              <a href="/"><img src="@/assets/img/logo.png" alt="logo" /> </a>
            </div>
            <div class="user mobile--only">
              <div class="my-account">
                <div class="item">
                  <div class="balance">
                    <div class="title with-diamond box">
                      {{ $t('header.balance') }}
                    </div>
                    <div class="number">
                      {{ coinbase === '' ? 0 : trxBalance }} TRX
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div v-if="!isInjected" class="login_btn" @click="login">
                    {{ $t('login') }}
                  </div>
                  <div class="balance" v-else>
                    <div class="title">{{ $t('header.address') }}</div>
                    <div class="text-copy svelte-1mv0e4h">
                      {{ coinbase ? coinbase : '--' }}
                      <span
                        class="icon-copy"
                        v-clipboard:copy="coinbase"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="timer-bar">
              <div class="title">
                {{ $t('header.timer') }}
                <span class="big desktop--only">one</span>
                <span>{{ $t('header.club') }}</span>
              </div>
              <div class="timer">
                <div class="flip">
                  <FlipClock></FlipClock>
                </div>
              </div>
            </div>
            <div class="navbar-custom">
              <change-lang />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import TronWeb from 'tronweb';
import FlipClock from './timer/FlipClock.vue';
import ChangeLang from './ChangeLang.vue';

export default {
  components: {
    FlipClock,
    ChangeLang,
  },
  data() {
    return {};
  },
  mounted() {
    // this.login();
  },
  computed: {
    ...mapState({
      // coinbase: (state) => state.tron.tron.coinbase,
      isInjected: (state) => state.tron.tron.isInjected,
      balance: (state) => state.tron.tron.balance,
    }),
    coinbase: {
      get() {
        return this.$store.state.tron.tron.coinbase;
      },
      set(value) {
        this.$store.commit('setCoinbase', value);
      },
    },
    trxBalance() {
      return TronWeb.fromSun(this.balance);
    },
  },
  methods: {
    onCopy: function (e) {
      // alert('You just copied: ' + e.text);
      this.$message.success(e.text);
    },
    onError: function (e) {
      alert('Failed to copy texts' + e);
    },
    async login() {
      if (!window.tronWeb) {
        this.$modal.warning({
          title: this.$t('tips'),
          content: this.$t('wallet_plugin_first'),
        });
      } else {
        if (!window.tronWeb.ready) {
          this.$modal.warning({
            title: this.$t('tips'),
            content: this.$t('unlock_first'),
          });
        } else {
          this.$store.dispatch('registerTronWeb');
        }
      }
    },
  },
  watch: {
    // 监听 store里面的数据
    '$store.state.tron.tron.coinbase': {
      deep: true,
      handler: function (newValue, oldValue) {
        console.log(newValue, oldValue, '12345444444');
      },
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 25px 12px;
  background: #171e26;
  img.logo {
    max-height: 60px;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
  }
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .align-items-center {
    align-items: center;
  }
  .user-info {
    display: flex;
    > .item {
      display: flex;
      align-items: center;
    }
  }
  .my-account {
    display: flex;
    flex-wrap: wrap;
    background: #131921;
    position: relative;
    margin-right: 40px;
    .item {
      padding: 18px 12px 18px 18px;
      display: flex;
      align-items: center;
      border-right: 2px solid #171e26;
      .balance {
        display: inline-block;
      }
      .title {
        font-size: 10px;
        color: #89929f;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 6px;
      }
      .number {
        font-size: 14px;
        letter-spacing: 1.5px;
      }
      .icon-copy {
        background: url(../assets/img/icon-copy.png) no-repeat center left;
        background-size: contain;
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-left: 4px;
        vertical-align: bottom;
        cursor: pointer;
      }
      .title.with-diamond:after {
        content: '';
        background: url(../assets/img/icon-diamond.png) no-repeat center left;
        background-size: contain;
        width: 11px;
        height: 13px;
        display: inline-block;
        margin-left: 4px;
        vertical-align: middle;
      }
    }
  }
  .text-right {
    text-align: right;
  }
  .d-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .timer-bar {
    padding: 0;
    display: inline-block;
    .title {
      text-transform: uppercase;
      font-size: 12px;
      position: relative;
      padding-left: 25px;
      float: left;
      margin: 8px 5px 5px 0;
      letter-spacing: 1px;
      &:before {
        content: '';
        background: url(../assets/img/icon-timer.png) no-repeat center;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .big {
        font-size: 19px;
        line-height: 20px;
        color: #f81a44;
        vertical-align: baseline;
      }
      span:not(.big) {
        display: block;
        font-size: 9px;
      }
    }
    .timer {
      float: left;
      text-transform: uppercase;
      font-size: 12px;
      position: relative;
      padding-left: 25px;
      float: left;
      margin: 8px 5px 5px 0;
      letter-spacing: 1px;
      .flip {
        display: inline-block;
        font-size: 16px;
        color: #ff0030;
        font-weight: 500;
        text-shadow: 0 0 20px #f81a44;
        margin-left: 2px;
        position: relative;
        span {
          background: #10161f;
          text-align: center;
          vertical-align: middle;
          float: left;
          margin: 0 2px;
          width: 24px;
          height: 35px;
          line-height: 35px;
        }
        .flip-label {
          font-size: 8px;
          line-height: 10px;
          opacity: 0.5;
          color: #fff;
          text-transform: uppercase;
          text-align: center;
          letter-spacing: 2px;
          font-weight: 400;
          clear: both;
          float: left;
          width: 100%;
          width: 100%;
          position: absolute;
          bottom: -18px;
        }
      }
    }
  }
  .navbar-custom {
    display: inline-block;
    min-width: 160px;
    height: 35px;
  }
}
.square:before,
.square:after {
  content: '';
  background: url(../assets/img/square-top-bg.png) no-repeat center;
  background-size: contain;
  width: 37px;
  height: 8px;
  position: absolute;
  left: -2px;
  top: -2px;
}
.square:after {
  background-image: url(../assets/img/square-bottom-bg.png);
  top: auto;
  bottom: -2px;
}
.login_btn {
  display: inline-block;
  cursor: pointer;
  width: 100%;
  height: auto;
}
</style>
