<template>
  <div class="smart">
    <div class="block sidebar">
      <div class="block-title block-title-red">
        <span class="svelte-1wahqxj"
          ><h2 class="svelte-1wahqxj">{{ $t('smart.address') }}</h2>
          <p class="contrast">{{ $t('smart.tip') }}</p></span
        >
        <div class="tooltip right">
          <div class="icon-question"></div>
        </div>
      </div>
      <div class="block-subtitle">
        <p>{{ $t('smart.realTime') }}</p>
      </div>
      <div class="block-content">
        <ul></ul>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('smart.total') }}</div>
          <div class="number">{{ fullJoinedAmount }}</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('smart.received') }}</div>
          <div class="number svelte-1wahqxj">{{ totalWithdrawAmount }}</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('smart.todayReceived') }}</div>
          <div class="number svelte-1wahqxj">
            {{ todayTotalWithdrawAmount }}
          </div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('smart.participation') }}</div>
          <div class="number svelte-1wahqxj">{{ holdUserNum }}</div>
        </div>
        <!-- <div class="grand-total svelte-1wahqxj">
          <div class="title">动态池</div>
          <div class="number svelte-1wahqxj">7,484,393</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">保障池</div>
          <div class="number svelte-1wahqxj">7,484,393</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fullJoinedAmount: 0,
      totalWithdrawAmount: 0,
      todayTotalWithdrawAmount: 0,
      holdUserNum: 0,
    };
  },
  mounted() {
    this.getFullJoined();
    this.getTotalWithdraw();
    this.getTodayTotalWithdraw();
    this.getHoldUserNum();
  },
  methods: {
    async getFullJoined() {
      const fullJoinedAmount = await this.API.getFullJoined();
      this.fullJoinedAmount = this.API.fromSun(fullJoinedAmount);
      console.log(this.API.fromSun(fullJoinedAmount), 'fullJoinedAmount');
    },
    async getTotalWithdraw() {
      const totalWithdrawAmount = await this.API.getTotalWithdraw();
      this.totalWithdrawAmount = this.API.fromSun(totalWithdrawAmount);
      console.log(this.API.fromSun(totalWithdrawAmount), 'totalWithdrawAmount');
    },
    async getTodayTotalWithdraw() {
      const todayTotalWithdrawAmount = await this.API.getTodayTotalWithdraw();
      this.todayTotalWithdrawAmount = this.API.fromSun(
        todayTotalWithdrawAmount
      );
      console.log(
        this.API.fromSun(todayTotalWithdrawAmount),
        'todayTotalWithdrawAmount'
      );
    },
    async getHoldUserNum() {
      const holdUserNum = await this.API.getHoldUserNum();
      this.holdUserNum = this.API.toDecimal(holdUserNum);
      console.log(this.API.toDecimal(holdUserNum), holdUserNum, 'holdUserNum');
    },
  },
};
</script>
<style lang="less" scoped>
.smart {
  .sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #171e26;
    .block-title {
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      margin-bottom: 0;
      span {
        padding: 8px 30px 11px 20px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        h2.svelte-1wahqxj {
          color: #fff;
          font-weight: 700;
          text-shadow: 0 1px 0 #8e8e8e00;
          font-size: 15px;
          margin: 0;
          letter-spacing: 1.5px;
        }
      }
      p.contrast {
        color: #808080;
        font-weight: 600;
        margin: 0;
        letter-spacing: 1.5px;
      }
      .tooltip {
        position: absolute;
        right: 31px;
        top: 19px;
        display: inline-block;
        z-index: 9;
        opacity: 1;
        .icon-question {
          background: url(../assets/img/icon-question-mark.png) no-repeat center;
          background-size: contain;
          width: 12px;
          min-width: 12px;
          height: 12px;
          cursor: pointer;
        }
      }
    }
    .block-subtitle {
      padding-top: 10px;
      padding-bottom: 10px;
      background: #0f141f;
      background: -moz-linear-gradient(
        left,
        rgba(15, 20, 31, 0) 0%,
        rgba(15, 20, 31, 0) 1%,
        #0f141f 20%,
        #0f141f 51%,
        #0f141f 80%,
        rgba(15, 20, 31, 0) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(15, 20, 31, 0) 0%,
        rgba(15, 20, 31, 0) 1%,
        #0f141f 20%,
        #0f141f 51%,
        #0f141f 80%,
        rgba(15, 20, 31, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(15, 20, 31, 0) 0%,
        rgba(15, 20, 31, 0) 1%,
        #0f141f 20%,
        #0f141f 51%,
        #0f141f 80%,
        rgba(15, 20, 31, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000f141f', endColorstr='#000f141f', GradientType=1);
      margin-bottom: 0;
      font-size: 11px;
      line-height: 12px;
      font-style: italic;
      text-align: center;
      padding: 6px 0;
      position: relative;

      &:after,
      &:before {
        content: '';
        background: url(../assets/img/right-border.png) no-repeat center right;
        background-size: contain;
        width: 4px;
        height: 49px;
        max-height: 100%;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
      }
      // .left-sidebar:before {
      //   left: 0;
      //   right: auto;
      //   -webkit-transform: rotate(180deg);
      //   -moz-transform: rotate(180deg);
      //   -ms-transform: rotate(180deg);
      //   -o-transform: rotate(180deg);
      //   transform: rotate(180deg);
      // }
      p {
        margin: 0;
      }
    }
  }
  .sidebar.left-sidebar {
    .block-subtitle {
      &:before {
        left: 0;
        right: auto;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
  .block-content {
    margin-top: 0;
    height: 100%;
    overflow: hidden;
    .grand-total.svelte-1wahqxj {
      background: #0f141c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px;
    }
    .grand-total {
      .title {
        font-size: 9px;
        letter-spacing: 1px;
        line-height: 13px;
        font-style: italic;
        text-transform: uppercase;
        text-shadow: 0px -1px 15px rgba(255, 0, 168, 0.5);
        max-width: 40%;
        text-align: center;
      }
      .number {
        font-size: 18px;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        &:after {
          content: '';
          background: url(../assets/img/icon-diamond_filled-red2.png) no-repeat
            center;
          background-size: contain;
          width: 30px;
          height: 30px;
          float: right;
          margin: 0 0 0 5px;
        }
      }
    }
  }
}
</style>
