<template>
  <div class="personal">
    <div class="block sidebar">
      <div class="block-title block-title-red">
        <span class="svelte-1wahqxj">
          <h2 class="svelte-1wahqxj"><i></i>{{ withdrawableReward }}</h2>
          <p class="contrast">{{ $t('personal.todayCollect') }}</p>
        </span>
        <div class="tooltip right">
          <!-- <div class="icon-question"></div> -->
          <a-button class="btn" @click="withdraw()" :loading="loading">
            {{ $t('personal.withdraw') }}
          </a-button>
        </div>
      </div>
      <div class="block-content">
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.referrer') }}</div>
          <div class="number">
            {{
              referrer === 'T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb'
                ? '--'
                : referrer
            }}
          </div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.joinAmount') }}</div>
          <div class="number">{{ joinAmount }}</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.totalReward') }}</div>
          <div class="number">{{ totalReward }}</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.withdrawed') }}</div>
          <div class="number">{{ withdrawed }}</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.pendingReward') }}</div>
          <div class="number svelte-1wahqxj">{{ pendingReward }}</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.inviteeReward') }}</div>
          <div class="number svelte-1wahqxj">{{ inviteeReward }}</div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">
            {{ $t('personal.withdrawablePendingReward') }}
          </div>
          <div class="number svelte-1wahqxj">
            {{ withdrawablePendingReward }}
          </div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">
            {{ $t('personal.withdrawableInviteeReward') }}
          </div>
          <div class="number svelte-1wahqxj">
            {{ withdrawableInviteeReward }}
          </div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.historyTotalReward') }}</div>
          <div class="number svelte-1wahqxj">
            {{ historyTotalReward }}
          </div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.historyStaticReward') }}</div>
          <div class="number svelte-1wahqxj">
            {{ historyStaticReward }}
          </div>
        </div>
        <div class="grand-total svelte-1wahqxj">
          <div class="title">{{ $t('personal.historyInviteeReward') }}</div>
          <div class="number svelte-1wahqxj">
            {{ historyInviteeReward }}
          </div>
        </div>
        <!-- <div class="grand-total svelte-1wahqxj">
          <div class="title">分享伙伴</div>
          <div class="number svelte-1wahqxj">7,484,393个</div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      referrer: '',
      joinAmount: 0,
      totalReward: 0,
      withdrawed: 0,
      withdrawableReward: 0,
      pendingReward: 0,
      inviteeReward: 0,
      withdrawablePendingReward: 0,
      withdrawableInviteeReward: 0,
      historyTotalReward: 0,
      historyStaticReward: 0,
      historyInviteeReward: 0,
      loading: false,
    };
  },
  computed: {
    coinbase: {
      get() {
        return this.$store.state.tron.tron.coinbase;
      },
      set(value) {
        this.$store.commit('setCoinbase', value);
      },
    },
  },
  mounted() {
    this.getReferrer();
    this.getJoinAmount();
    this.getTotalReward();
    this.getWithdrawed();
    this.getWithdrawableReward();
    this.getPendingReward();
    this.getInviteeReward();
    this.getWithdrawablePendingReward();
    this.getWithdrawableInviteeReward();
    this.getHistoryTotalReward();
    this.getHistoryStaticReward();
    this.getHistoryInviteeReward();
  },
  methods: {
    async withdraw() {
      this.loading = true;
      const withdrawRes = await this.API.withdraw();
      console.log(withdrawRes, 'withdraw');
      this.loading = false;
    },
    async getReferrer() {
      const referrer = await this.API.getReferrer(this.coinbase);
      this.referrer = this.API.fromHex(referrer);
      console.log(this.referrer, 'referrer');
    },
    async getJoinAmount() {
      const joinAmount = await this.API.getJoinAmount(this.coinbase);
      this.joinAmount = this.API.fromSun(joinAmount);
      console.log(this.joinAmount, 'joinAmount');
    },
    async getTotalReward() {
      const totalReward = await this.API.getTotalReward(this.coinbase);
      this.totalReward = this.API.fromSun(totalReward);
      console.log(this.totalReward, 'totalReward');
    },
    async getWithdrawed() {
      const withdrawed = await this.API.getWithdrawed(this.coinbase);
      this.withdrawed = this.API.fromSun(withdrawed);
      console.log(this.withdrawed, 'withdrawed');
    },
    //
    async getWithdrawableReward() {
      const withdrawableReward = await this.API.getWithdrawableReward(
        this.coinbase
      );
      this.withdrawableReward = this.API.fromSun(withdrawableReward);
      console.log(this.withdrawableReward, 'withdrawableReward');
    },
    async getPendingReward() {
      const pendingReward = await this.API.getPendingReward(this.coinbase);
      this.pendingReward = this.API.fromSun(pendingReward);
      console.log(this.pendingReward, 'pendingReward');
    },
    async getInviteeReward() {
      const inviteeReward = await this.API.getInviteeReward(this.coinbase);
      this.inviteeReward = this.API.fromSun(inviteeReward);
      console.log(this.inviteeReward, 'inviteeReward');
    },
    async getWithdrawablePendingReward() {
      const withdrawablePendingReward = await this.API.getWithdrawablePendingReward(
        this.coinbase
      );
      this.withdrawablePendingReward = this.API.fromSun(
        withdrawablePendingReward
      );
      console.log(this.withdrawablePendingReward, 'withdrawablePendingReward');
    },
    async getWithdrawableInviteeReward() {
      const withdrawableInviteeReward = await this.API.getWithdrawableInviteeReward(
        this.coinbase
      );
      this.withdrawableInviteeReward = this.API.fromSun(
        withdrawableInviteeReward
      );
      console.log(this.withdrawableInviteeReward, 'withdrawableInviteeReward');
    },
    async getHistoryTotalReward() {
      const historyTotalReward = await this.API.getHistoryTotalReward(
        this.coinbase
      );
      this.historyTotalReward = this.API.fromSun(historyTotalReward);
      console.log(this.historyTotalReward, 'historyTotalReward');
    },
    async getHistoryStaticReward() {
      const historyStaticReward = await this.API.getHistoryStaticReward(
        this.coinbase
      );
      this.historyStaticReward = this.API.fromSun(historyStaticReward);
      console.log(this.historyStaticReward, 'historyStaticReward');
    },
    async getHistoryInviteeReward() {
      const historyInviteeReward = await this.API.getHistoryInviteeReward(
        this.coinbase
      );
      this.historyInviteeReward = this.API.fromSun(historyInviteeReward);
      console.log(this.historyInviteeReward, 'historyInviteeReward');
    },
    //getHistoryInviteeReward
  },
};
</script>
<style lang="less" scoped>
.personal {
  .sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: #171e26;
    .block-title {
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 2px;
      position: relative;
      width: 100%;
      margin-bottom: 0;
      span {
        padding: 8px 30px 11px 20px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        h2.svelte-1wahqxj {
          color: #fff;
          font-weight: 700;
          text-shadow: 0 1px 0 #8e8e8e00;
          font-size: 15px;
          margin: 0;
          letter-spacing: 1.5px;
          i {
            &:after {
              content: '';
              background: url(../../assets/img/icon-diamond_filled-red2.png)
                no-repeat center;
              background-size: contain;
              width: 30px;
              height: 30px;
              float: left;
              margin: 0 0 0 -10px;
            }
          }
        }
      }
      p.contrast {
        color: #808080;
        font-weight: 600;
        margin: 0;
        letter-spacing: 1.5px;
      }
      .tooltip {
        position: absolute;
        right: 31px;
        top: 19px;
        display: inline-block;
        z-index: 9;
        opacity: 1;
        .icon-question {
          background: url(../../assets/img/icon-question-mark.png) no-repeat
            center;
          background-size: contain;
          width: 12px;
          min-width: 12px;
          height: 12px;
          cursor: pointer;
        }
      }
    }
    .block-subtitle {
      padding-top: 10px;
      padding-bottom: 10px;
      background: #0f141f;
      background: -moz-linear-gradient(
        left,
        rgba(15, 20, 31, 0) 0%,
        rgba(15, 20, 31, 0) 1%,
        #0f141f 20%,
        #0f141f 51%,
        #0f141f 80%,
        rgba(15, 20, 31, 0) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(15, 20, 31, 0) 0%,
        rgba(15, 20, 31, 0) 1%,
        #0f141f 20%,
        #0f141f 51%,
        #0f141f 80%,
        rgba(15, 20, 31, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(15, 20, 31, 0) 0%,
        rgba(15, 20, 31, 0) 1%,
        #0f141f 20%,
        #0f141f 51%,
        #0f141f 80%,
        rgba(15, 20, 31, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000f141f', endColorstr='#000f141f', GradientType=1);
      margin-bottom: 0;
      font-size: 11px;
      line-height: 12px;
      font-style: italic;
      text-align: center;
      padding: 6px 0;
      position: relative;

      &:after,
      &:before {
        content: '';
        background: url(../../assets/img/right-border.png) no-repeat center
          right;
        background-size: contain;
        width: 4px;
        height: 49px;
        max-height: 100%;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
      }
      // .left-sidebar:before {
      //   left: 0;
      //   right: auto;
      //   -webkit-transform: rotate(180deg);
      //   -moz-transform: rotate(180deg);
      //   -ms-transform: rotate(180deg);
      //   -o-transform: rotate(180deg);
      //   transform: rotate(180deg);
      // }
      p {
        margin: 0;
      }
    }
  }
  .sidebar.left-sidebar {
    .block-subtitle {
      &:before {
        left: 0;
        right: auto;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
  .block-content {
    margin-top: 0;
    height: 100%;
    overflow: hidden;
    .grand-total.svelte-1wahqxj {
      background: #0f141c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 5px;
    }
    .grand-total {
      .title {
        font-size: 9px;
        letter-spacing: 1px;
        line-height: 13px;
        font-style: italic;
        text-transform: uppercase;
        text-shadow: 0px -1px 15px rgba(255, 0, 168, 0.5);
        max-width: 40%;
        text-align: center;
      }
      .number {
        font-size: 18px;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        &:after {
          content: '';
          background: url(../../assets/img/icon-diamond_filled-red2.png)
            no-repeat center;
          background-size: contain;
          width: 30px;
          height: 30px;
          float: right;
          margin: 0 0 0 5px;
        }
      }
    }
  }
}
.btn {
  padding: 9px 60px !important;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  position: relative;
  display: inline-block;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 11px) 100%, 11px 100%);
  clip-path: polygon(0 0, 100% 0, calc(100% - 11px) 100%, 11px 100%);
  background: #a70e35;
  background: -moz-linear-gradient(0deg, #a70e35 0%, #ec1842 50%, #a70e35 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #a70e35),
    color-stop(50%, #ec1842),
    color-stop(100%, #a70e35)
  );
  background: -webkit-linear-gradient(
    0deg,
    #a70e35 0%,
    #ec1842 50%,
    #a70e35 100%
  );
  background: -o-linear-gradient(0deg, #a70e35 0%, #ec1842 50%, #a70e35 100%);
  background: -ms-linear-gradient(0deg, #a70e35 0%, #ec1842 50%, #a70e35 100%);
  background: linear-gradient(90deg, #a70e35 0%, #ec1842 50%, #a70e35 100%);
}
</style>
