import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh_CN from './zh';
import en_US from './en';
// import store from '../store';
import Cookies from 'js-cookie'

Vue.use(VueI18n);

// const LANG = store.getters.getLanguage;
const LANG = Cookies.get('language') || 'zh_CN';

export default new VueI18n({
    locale: LANG, 
    messages:{
        zh_CN,
        en_US   
    }
})
