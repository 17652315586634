<template>
  <div class="personal">
    <div class="tabs">
      <span
        class="tab-title"
        :class="cur === index ? 'active' : ''"
        v-for="(item, index) of tab"
        :key="index"
        @click="tabChange(index)"
      >
        {{ item }}
      </span>
    </div>
    <div class="tab-content">
      <div v-for="(item, index) of content" :key="index" v-show="cur == index">
        <component :is="componentName"></component>
      </div>
    </div>
  </div>
</template>
<script>
import XData from './personal/Data';
import XShare from './personal/Share';

export default {
  data() {
    return {
      // tab: ['个人数据', '个人推广'],
      content: ['个人数据', '个人推广'],
      cur: 0,
      componentName: 'XData',
    };
  },
  components: {
    XData,
    XShare,
  },
  computed: {
    tab() {
      return [this.$t('personal.tabOne'), this.$t('personal.tabTwo')];
    },
  },
  methods: {
    tabChange(val) {
      this.cur = val;
      if (this.cur === 0) {
        this.componentName = 'XData';
      } else if (this.cur === 1) {
        this.componentName = 'XShare';
      } else {
        this.componentName = 'XData';
      }
    },
  },
};
</script>
<style lang="less" scoped>
.personal {
  // display: flex;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: #171e26;
  .tabs {
    margin: 12px 0 0;
    text-align: center;
    // height: 100%;
    .tab-title {
      background: #10161f;
      border: 4px solid #10161f;
      font-size: 13px;
      color: #535f6a;
      letter-spacing: 1px;
      height: 48px;
      padding: 0 30px;
      line-height: 40px;
      position: relative;
      z-index: 1;
      float: none;
      border-radius: 4px;
      // margin: 0 -4px;
      display: inline-block;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
    }
    .tab-title.active {
      color: #fff;
    }
    .tab-title.active:after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 4px;
      height: 100%;
      background: #28333f;
    }
  }
  .tab-content {
    padding: 20px 15px 30px;
    margin: 27px 0 0;
    background: #0f141c;
    height: calc(100% - 80px);
    max-height: 100%;
    position: relative;
  }
}
</style>
