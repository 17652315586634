<template>
  <div class="share">
    <div class="head">
      <div class="icon">
        <span class="up-indicator" aria-label="scroll" aria-hidden="true"
          ><svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            class="up-svgchevron"
            viewBox="0 0 30 36"
          >
            <path class="a3" d="M0,0l15,16L30,0"></path>
            <path class="a2" d="M0,10l15,16l15-16"></path>
            <path class="a1" d="M0,20l15,16l15-16"></path></svg
        ></span>
      </div>
      <div class="title">
        <h3>
          <b>{{ inviteeNum }}</b> 人数
        </h3>
        <!-- <p>They can't wait until i glitch</p> -->
      </div>
      <div class="total">
        <p>总奖金</p>
        <div class="number with-diamond">{{ inviteeReward }}</div>
      </div>
    </div>
    <div class="table-responsive-sm data bg-blue">
      <div class="table-wrapper-scroll-y">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="id">名次</th>
              <th style="width: 230px">地址</th>
              <th><span class="icon-diamond"></span>提供流动性</th>
              <th class="text-right">
                <span class="icon-hexagon"><span>奖金</span></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="top">
              <td class="id"># 01</td>
              <td class="address svelte-20h1ua">TFgtDAUAU2Fb...w8Y1oPgUMBLq</td>
              <td class="number">3,997,112</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 02</td>
              <td class="address svelte-20h1ua">TWAcyScAmhZm...bP33iCNmM9L3</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
            <tr>
              <td class="id"># 03</td>
              <td class="address svelte-20h1ua">TRTE7n38u1F2...dqdEwiVeUCbz</td>
              <td class="number">1,221,341</td>
              <td class="text-right">1</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      inviteeNum: 0,
      inviteeReward: 0,
    };
  },
  computed: {
    ...mapState({
      // coinbase: (state) => state.tron.tron.coinbase,
      isInjected: (state) => state.tron.tron.isInjected,
      balance: (state) => state.tron.tron.balance,
    }),
    coinbase: {
      get() {
        return this.$store.state.tron.tron.coinbase;
      },
      set(value) {
        this.$store.commit('setCoinbase', value);
      },
    },
  },
  mounted() {
    this.getInviteeNum();
    this.getInviteeReward();
  },
  methods: {
    async getInviteeNum() {
      const inviteeNum = await this.API.getInviteeNum(this.coinbase);
      this.inviteeNum = this.API.toDecimal(inviteeNum);
      console.log(this.inviteeNum, 'inviteeNum');
    },
    async getInviteeReward() {
      const inviteeReward = await this.API.getInviteeReward(this.coinbase);
      this.inviteeReward = this.API.fromSun(inviteeReward);
      console.log(this.inviteeReward, 'inviteeReward');
    },
  },
};
</script>

<style lang="less" scoped>
.share {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head {
    clear: both;
    color: #fff;
    border: 1px solid #0d6169;
    background: url(../../assets/img/line-bg1.png) no-repeat bottom center;
    z-index: 9;
    height: 160px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 40px 40px 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-style: normal;
      color: #7d8ea6;
      .up-svgchevron {
        height: 40px;
        width: 40px;
        transform: rotate(180deg);
      }
    }
    .title {
      font-style: italic;
      text-align: left;
      margin-left: 30px;
      margin: 25px 0 6px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 1px;
      h3 {
        font-size: 22px;
        font-weight: 300;
        color: #fff;
        b {
          color: #ff0047;
          font-weight: bold;
          font-style: normal;
        }
      }
    }
    .total {
      font-size: 11px;
      margin-left: auto;
      .number {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;
      }
      .with-diamond:after {
        width: 14px;
        height: 18px;
        margin-left: 9px;
        content: '';
        background: url(../../assets/img/icon-diamond.png) no-repeat center left;
        background-size: contain;
        display: inline-block;
      }
    }
  }
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
p {
  margin: 0;
}
.up-svgchevron {
  height: 40px;
  width: 40px;
  transform: rotate(180deg);
  path {
    -webkit-animation: chevron-pulse 3s infinite cubic-bezier(0.4, 0, 0.2, 1);
    animation: chevron-pulse 3s infinite cubic-bezier(0.4, 0, 0.2, 1);
    fill: transparent;
    stroke: #00ffb4;
    stroke-width: 1px;
  }
  path.a3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  path.a2 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  path.a1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
}
.table-responsive-sm {
  height: 100%;
  padding: 10px 0 0;
  background: #0f141c;
  text-transform: uppercase;
  .table-wrapper-scroll-y {
    // max-height: calc(100% - 500px);
    max-height: 400px;
    padding-right: 20px;
    position: relative;
    overflow-x: auto;
    overflow-y: auto;
    display: block;
    scrollbar-color: #28333f #0f141c;
  }
  .table {
    font-size: 14px;
    text-align: left;
    letter-spacing: 1.5px;
    margin: 0;
    width: 100%;
    border-collapse: collapse;
    color: #fff;
    thead {
      font-size: 12px;
      border: 1px solid#1e262f;
      th {
        font-weight: 300;
        padding: 16px 18px;
        border: 0;
        background: #0f141c;
        padding: 0.5rem 0.75rem;
        position: relative;
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
        vertical-align: bottom;
        &:before {
          top: 0;
          // border-top: 1px solid #1b6664;
        }
        &:after {
          bottom: 0;
          // border-bottom: 1px solid #1b6664;
        }
      }
    }
    td.id {
      font-size: 10px;
    }
    td:nth-child(odd) {
      opacity: 0.4;
    }
    td {
      padding: 15px 18px;
      line-height: 18px;
      border-top: 0;
      vertical-align: top;
      border-bottom: 1px solid #1e262f;
    }
    .address.svelte-20h1ua {
      font-size: 10px;
      text-transform: none;
    }
    td.number {
      color: #404c59;
      opacity: 1;
    }
  }
}
th:after,
th:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}
.text-right {
  text-align: right !important;
}
.icon-diamond {
  position: absolute;
  left: 0;
  &:after {
    width: 10px;
    vertical-align: middle;
    margin-right: 12px;
    margin-top: -3px;
    content: '';
    background: url(../../assets/img/icon-diamond.png) no-repeat center left;
    background-size: contain;
    height: 14px;
    display: inline-block;
  }
}
</style>
