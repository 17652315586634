<template>
  <div class="language">
    <!-- <h1>{{ $t('title') }}</h1>
    <h2>{{ getLanguage }}</h2> -->
    <a-dropdown :trigger="['click']">
      <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
        <a-icon type="global" />
        {{ getLanguage === 'zh_CN' ? '中文' : 'English' }}
      </a>
      <template #overlay>
        <a-menu
          @click="(val) => changeLocale(val)"
          :selected-keys="[lang]"
          slot="overlay"
        >
          <a-menu-item key="zh_CN">
            <span role="img" aria-label="China"> 🇨🇳 </span>
            China (中国)
          </a-menu-item>
          <a-menu-item key="en_US">
            <span role="img" aria-label="USA"> 🇺🇸 </span>
            USA (美国)
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      langList: [
        { key: 'zh_CN', name: '简体中文', alias: '简体' },
        { key: 'en_US', name: 'English', alias: 'English' },
      ],
      lang: '',
    };
  },
  created() {},
  computed: {
    ...mapGetters(['getLanguage']),
  },
  methods: {
    ...mapMutations(['changeLanguage']),
    changeLocale(e) {
      const localeValue = e.key;
      this.changeLanguage(localeValue);
      this.$i18n.locale = localeValue;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>
.language {
  float: left;
  margin-left: 8px;
  position: relative;
}
a {
  color: #fff;
  font-size: 18px;
}
</style>
<style>
.ant-dropdown-menu {
  background: #0f0d19;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #fff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: #ff004e;
}
</style>