import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import dataV from "@jiaminghi/data-view";
import API from "@/api";

// Vue.use(dataV);
import "./assets/css/global.css"; // 用于覆盖上面定义的变量

import {
  Button,
  Spin,
  Modal,
  Radio,
  ConfigProvider,
  Row,
  Col,
  Tabs,
  Input,
  Space,
  message,
  Dropdown,
  Menu,
  Icon,
  Drawer,
  notification
} from "ant-design-vue";
Vue.use(Button);
Vue.use(Radio);
Vue.use(ConfigProvider);
Vue.use(Row);
Vue.use(Col);
Vue.use(Tabs);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Drawer);
Vue.use(Space);
Vue.use(Spin);

Vue.config.productionTip = false;

Vue.prototype.$message = message;
Vue.prototype.$modal = Modal;
Vue.prototype.$notification = notification;
Vue.prototype.API = new API();

message.config({
  duration: 2, // 持续时间
  top: `100px`, // 到页面顶部距离
  maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

import i18n from "./lang/i18n";

//  //判断是否链接钱包
// router.beforeEach(async (to, from, next) => {
//   console.log(window.tronWeb,'123')
//   if (window.tronWeb) {
//     let tronWeb = window.tronWeb;
//     // 2. check node connection，检查所需要的API是否都可以连通
//     const nodes = await tronWeb.isConnected();
//     const connected = !Object.entries(nodes).map(([name, connected]) => {
//         if (!connected) {
//             console.error(`Error: ${name} is not connected`);
//         }
//         return connected;
//     }).includes(false);
//     if (connected){
//         // 3. 如果一切正常，启动vue应用。
//       console.log('如果一切正常，启动vue应用。')
//       next();
//     } else {
//         console.error(`Error: TRON node is not connected`);
//         console.error('wait for tronLink');
//         next();
//     }
//   } else {
//       console.error('wait for tronLink3333');
//       next()
//   }
// });

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
