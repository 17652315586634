import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import tron from "./tron";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: Cookies.get("language")
  },

  getters: {
    getLanguage: state => state.language
  },
  mutations: {
    changeLanguage(state, lang) {
      console.log(lang);
      Cookies.set("language", lang);
      state.language = lang;
    }
  },
  actions: {},
  modules: {
    tron
  }
});
