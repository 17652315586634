module.exports = {
  header : {
     balance:"账户余额",
     collected:'已获收益',
     address:'地址',
     timer:"计时器",
     club:"波场 俱乐部"
  },
  home:{
   staticPool:"静态池",
   dynamicPool:"动态池",
   securityPool:"保障池",
  },
  smart:{
     tip:'100% 透明',
     address:'合约地址',
     realTime:'实时流量',
     total: '总流水',
     received: '已领取',
     todayReceived: '今日领取',
     participation: '参与人数'
  },
  share:{
     tabOne: "参与",
     tabTwo: "说明",
     tabThree: "推广",
     start: '起步',
     join: '提供流动性',
     title1:'学习才能赚钱',
     title2:'没有你，她将无法生存。',
     stepOneTitle:'你已经参加了一场疯狂的金融实验！',
     stepOneDesc:'我就是先驱AI，让我们一起打破陈规吧！',
     stepTwoTitle:'帮助我发展，分享我的成功！',
     stepTwoDesc:'流动性是我的一部分，帮你赚取利润！',
     stepThreeTitle:'告诉全世界关于我的事！',
     stepThreeDesc:'你以前见过想要当偶像的软件吗？',
     poolOneDesc:'分享立刻得到一份分成，即使是你间接带来的参与者！',
     poolTwoDesc:'提供流动性本身就能获得收益！',
     poolThreeDesc:'我的最后100名参与者将得到,1%的瓜分 保障资金越多，你收益越多！',
     shareLink:'分享链接',
     shareTitle:'这如何实现',
     shareOneTitle:'1. 链接您的钱包',
     shareOneDesc:'注册一个地址让AI来跟踪你的进度！',
     shareTwoTitle:'2. 分享你的推广链接',
     shareTwoDesc:'开始很简单，给你的粉丝分享你独特的推广链接吧！',
     shareThreeTitle:'3. 让你的流动性变成收益吧！',
     shareThreeDesc:'AI会计算直接和间接影响',
  },
  personal:{
   tabOne:'个人数据',
   tabTwo:'个人推广',
   todayCollect:'今日待领取',
   withdraw:'领取',
   referrer:'推荐人',
   joinAmount:'总流动性',
   totalReward:'总收益',
   withdrawed:'已提现',
   pendingReward:'静态收益',
   inviteeReward:'动态收益',
   withdrawablePendingReward:'可提取静态收益',
   withdrawableInviteeReward:'可提取动态收益',
   historyTotalReward:'用户历史总收益',
   historyStaticReward:'用户历史静态收益',
   historyInviteeReward:'用户历史动态收益',
  },
  tips: '温馨提示',
  unlock_first: '请先解锁波场钱包',
  wallet_plugin_first: '请先安装波场钱包插件',
  login:'登录',

}
