<template>
  <div id="app">
    <a-config-provider v-if="isReady" :locale="getLocaleLang">
      <router-view />
    </a-config-provider>
    <x-loading v-if="!isReady" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import en_US from 'ant-design-vue/lib/locale-provider/en_US';
import XLoading from '@/components/Loading.vue';
export default {
  name: 'app',
  data() {
    return {
      locale: en_US,
    };
  },
  components: {
    XLoading,
  },
  // mounted() {
  //   // setTimeout(this.connectTronLink, 100);
  //   // this.connectTronLink();
  // },
  computed: {
    ...mapGetters(['getLanguage']),
    getLocaleLang() {
      let Language = null;
      switch (this.getLanguage) {
        case 'en_US':
          Language = en_US;
          break;
        default:
          Language = zh_CN;
      }
      return Language;
    },
    isReady() {
      return this.$store.state.tron.tron.isInjected;
    },
  },
  async mounted() {
    // let that = this;
    const waitForGlobal = async () => {
      if (window.tronWeb && window.tronWeb.ready) {
        if (!window.tronWeb.defaultAddress.base58) {
          window.tronWeb.on('addressChanged', () => {
            this.ready();
          });
        } else {
          this.ready();
        }
      } else {
        setTimeout(async () => {
          await waitForGlobal();
        }, 100);
      }
    };
    waitForGlobal().then();
  },
  methods: {
    ready() {
      this.API.init();
      this.$store.dispatch('registerTronWeb');
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  font-size: 16px;
  min-height: 100vh;
  color: #fff;
  margin: 0;
  padding: 0;
  background-color: #0f141c;
}

img {
  vertical-align: middle;
  border-style: none;
}
</style>
