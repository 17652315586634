<template>
  <div class="join">
    <div class="coinbase">
      <div class="title">{{ $t('share.shareLink') }}</div>
      <div class="text-copy svelte-1mv0e4h">
        {{ coinbase ? url + '?ref=' + coinbase : '--' }}
        <span
          class="icon-copy"
          v-clipboard:copy="url + '?ref=' + coinbase"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
        ></span>
      </div>
    </div>
    <div class="steps">
      <h4 class="title">{{ $t('share.shareTitle') }}</h4>
      <ul>
        <li>
          <div class="inner">
            <div class="icon icon-share with-icon-plane"></div>
            <h6>{{ $t('share.shareOneTitle') }}</h6>
            <p>{{ $t('share.shareOneDesc') }}</p>
            <div class="icon-question red1 mobile--only"></div>
          </div>
        </li>
        <li>
          <div class="inner">
            <div class="icon icon-paper-plane with-icon-plane-2"></div>
            <h6>{{ $t('share.shareTwoTitle') }}</h6>
            <p>{{ $t('share.shareTwoDesc') }}</p>
            <div class="icon-question red1 mobile--only"></div>
          </div>
        </li>
        <li>
          <div class="inner">
            <div class="icon icon-line-chart with-icon-visible"></div>
            <h6>{{ $t('share.shareThreeTitle') }}</h6>
            <p>{{ $t('share.shareThreeDesc') }}</p>
            <div class="icon-question red1 mobile--only"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      // coinbase: (state) => state.tron.tron.coinbase,
    }),
    coinbase: {
      get() {
        return this.$store.state.tron.tron.coinbase;
      },
      set(value) {
        this.$store.commit('setCoinbase', value);
      },
    },
    url() {
      return window.location.href;
    },
  },
  methods: {
    onCopy: function (e) {
      // alert('You just copied: ' + e.text);
      this.$message.success(e.text);
    },
    onError: function (e) {
      alert('Failed to copy texts' + e);
    },
  },
};
</script>
<style lang="less" scoped>
.coinbase {
  padding: 0 15px 40px;
  .title {
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    position: relative;
    display: table;
    margin: 0 auto 20px;
    padding: 0 35px;
    color: #fff;
  }
  .text-copy {
    font-size: 16px;
  }
  .icon-copy {
    background: url(../../assets/img/icon-copy.png) no-repeat center left;
    background-size: contain;
    width: 17px;
    height: 17px;
    display: inline-block;
    margin-left: 4px;
    vertical-align: bottom;
    cursor: pointer;
    font-size: 20px;
  }
}

.steps {
  padding: 0 15px 20px;
  .title {
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    position: relative;
    display: table;
    margin: 0 auto 40px;
    padding: 0 35px;
    color: #fff;
    &:before {
      content: '';
      margin: auto;
      z-index: 2;
      position: absolute;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      width: 0;
      height: calc(100% + 8px);
      top: 0;
      bottom: 0;
      border-left: 4px solid #3e5064;
      left: 0;
    }
    &:after {
      content: '';
      margin: auto;
      z-index: 2;
      position: absolute;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      width: 0;
      height: calc(100% + 8px);
      top: 0;
      bottom: 0;
      border-right: 4px solid #3e5064;
      right: 0;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    li {
      width: 33%;
      text-align: left;
      position: relative;
      padding: 1px;
      background: #322158;
      z-index: 1;
      font-size: 14px;
      -webkit-clip-path: polygon(
        calc(100% - 10px) 0%,
        100% 10px,
        100% 100%,
        0 100%,
        0 0
      );
      clip-path: polygon(
        calc(100% - 10px) 0%,
        100% 10px,
        100% 100%,
        0 100%,
        0 0
      );
      -webkit-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -ms-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;

      &:before {
        content: '';
        position: absolute;
        background: #0f141c;
        width: 100%;
        width: calc(100% - 2px);
        height: 100%;
        height: calc(100% - 2px);
        z-index: -1;
        top: 1px;
        left: 1px;
        -webkit-clip-path: polygon(
          calc(100% - 10px) 0%,
          100% 10px,
          100% 100%,
          0 100%,
          0 0
        );
        clip-path: polygon(
          calc(100% - 10px) 0%,
          100% 10px,
          100% 100%,
          0 100%,
          0 0
        );
      }
      .inner {
        padding: 42px 18px 20px;
      }
      .icon {
        position: relative;
      }
      .icon-share {
        background: url(../../assets/img/icon-share.png) no-repeat center;
        background-size: contain;
        width: 29px;
        height: 29px;
        font-size: 0;
        display: inline-block;
      }
      .icon-paper-plane {
        background: url(../../assets/img/icon-paper-plane-red.png) no-repeat
          center;
        background-size: contain;
        width: 24px;
        height: 24px;
        display: inline-block;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .icon.with-icon-plane-2 {
        &:before {
          width: 14px;
          height: 14px;
          top: -20px;
          left: 0px;
          opacity: 0.4;
          content: '';
          background: url(../../assets/img/icon-paper-plane.png) no-repeat
            center;
          background-size: contain;
          right: -29px;
          position: absolute;
        }
        &:after {
          content: '';
          background: url(../../assets/img/icon-paper-plane.png) no-repeat
            center;
          background-size: contain;
          width: 33px;
          height: 33px;
          position: absolute;
          top: -32px;
          right: -29px;
          opacity: 0.5;
        }
      }
      .icon-line-chart {
        background: url(../../assets/img/icon-line-chart.png) no-repeat center;
        background-size: contain;
        width: 28px;
        height: 28px;
        display: inline-block;
      }
      .icon.with-icon-visible:after {
        content: '';
        background: url(../../assets/img/icon-visible.png) no-repeat center;
        background-size: contain;
        width: 25px;
        height: 25px;
        position: absolute;
        right: -30px;
      }
      h6 {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.6px;
        margin-bottom: 10px;
        margin-top: 21px;
        color: #fff;
      }
      p {
        opacity: 0.5;
        letter-spacing: 0.3px;
        text-align: justify;
      }
    }
  }
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-style: normal;
  color: #7d8ea6;
}
</style>
